import { State } from '../'
import { Action } from '../../Action'
import { SyncPromise } from '../../SyncPromise'
import * as registry from '../../registry'
import { store } from '../../store'
import { Config as IConfig, ConfigDownload } from './IConfig'

const TYPE = 'CONFIG_DATA'

export type Config = IConfig

export const Config = Object.freeze({
  get (): SyncPromise<Config> {
    return store.getState().config
  },
  promise: downloadConfig(),
})

export function _fromDownload (dl: ConfigDownload): Config {
  return {
    URL_BECOME_A_PATIENT: `${dl.URL_SHOPIFY}/pages/patients`,
    URL_MY_ORDERS: `${dl.URL_SHOPIFY}/account`,
    URL_PRIVACY_POLICY: `${dl.URL_SHOPIFY}/policies/privacy-policy`,
    URL_SHOP: `${dl.URL_SHOPIFY}/collections/all`,
    ...dl,
  }
}

function downloadConfig (): Promise<Config> {
  return fetch('/config.json')
    .then(r => r.json())
    .then(_fromDownload)
}

interface ConfigAction extends Action {
  readonly type: typeof TYPE
  readonly value: SyncPromise<Config>
}

function dispatchConfig (value: SyncPromise<Config>) {
  store.dispatch({ type: TYPE, value })
}

const isConfigAction = (x: any): x is ConfigAction =>
  x.type === TYPE

const reduce = (state: State, action: Action): State|undefined => {
  if (!isConfigAction(action)) return
  const config: SyncPromise<Config> = action.value
  return {
    ...state,
    config,
  }
}

registry.register(reduce)
SyncPromise.fromPromise(Config.promise, dispatchConfig)
