export const BLACK = '#010101'
export const GREY = '#afb1af'
export const GREY_LIGHT = '#f8f8f8'
export const WHITE = '#ffffff'

export const BLUE_DARK = '#00263E'
export const BLUE = '#347FD8'
export const BLUE_LIGHT = '#00B0EC'
export const BLUE_DEEP = '#174D7D'

export const STATUS_RED = 'red'
export const STATUS_AMBER = 'orange'
export const STATUS_GREEN = 'green'
