import { Card } from 'antd'
import React from 'react'
import { MapStateToProps, connect } from 'react-redux'
import Button from '../../../../../../components/Button'
import DocumentsList from '../../../../../../components/DocumentsList'
import { Beta } from '../../../../../../redux/State/Beta'
import { Client } from '../../../../../../redux/State/Client/index'
import { State } from '../../../../../../redux/State/index'
import { I18n, getI18n } from './DocumentsCard.i18n'

interface StateProps {
  beta: boolean
  canAmend: boolean
  canRenew: boolean
  registrationPackages?: Array<{statusCode: string}> | null
  i18n: I18n
  language: string
}

const DocumentsCardPres: React.FC<StateProps> = ({
  canAmend,
  canRenew,
  i18n,
  language,
  registrationPackages,
}) => {
  const showBtns = canAmend || canRenew
  const renderRegBtnText = () => registrationPackages?.some(el => el.statusCode === 'Approved' || el.statusCode === 'Expired') ? i18n.RENEW : i18n.APPLY

  return (
    <Card>
      <h2>{i18n.HEADING}</h2>
      <div style={{ marginBottom: 20 }}>{i18n.INTRO}</div>
      {showBtns && (
        <div
          style={{
            marginBottom: 20,
            display: 'flex',
            justifyContent: 'space-around',
            flexWrap: 'wrap',
          }}
        >
          {canAmend && (
            <Button
              style={{ marginTop: 5, marginRight: 10 }}
              onClick={() => {
                window.location.href = '/#/secure/client/profile-amendment'
              }}
            >
              {i18n.NAME_OR_ADDRESS}
            </Button>
          )}
          {canRenew && (
            <Button
              style={{ marginTop: 5 }}
              onClick={() => {
                window.location.href = '/#/secure/client/renewal'
              }}
            >
              {renderRegBtnText()}
            </Button>
          )}
        </div>
      )}
      <div>{i18n.INSTRUCTIONS}</div>
      <DocumentsList
        documents={language === 'fr-CA'
          ? [
            {
              link:
                'https://cdn.shopify.com/s/files/1/0009/9716/3066/files/Registration_Application_FR_PRINT_VER_8.pdf',
              name: 'Demande d\'inscription',
            },
          ]
          : [
            {
              link:
                'https://cdn.shopify.com/s/files/1/0009/9716/3066/files/Registration_Application_EN_PRINT_VER_8.pdf',
              name: 'Registration Application',
            },
          ]
        }
      />
    </Card>
  )
}

const mapStateToProps: MapStateToProps<StateProps, {}, State> = (
  state,
  props,
): StateProps => {
  const beta = Beta.is()
  const canAmend = Client.get().map(c => c.canAmend).value ?? false
  const canRenew = Client.get().map(c => c.canRenew).value ?? false
  const registrationPackages = Client.get().map(c => c.registrationPackages).value ?? null

  return { beta, canAmend, canRenew, registrationPackages, i18n: getI18n(state.language), language: state.language }
}

export const DocumentsCard = connect(mapStateToProps)(DocumentsCardPres)
