import './style.less'
import React, { ReactElement } from 'react'
import { MapStateToProps, connect } from 'react-redux'
import { State } from '../../../redux/State'
import { Config } from '../../../redux/State/Config'
import { BLUE_LIGHT } from '../../../style'
import { I18n, getI18n } from './FooterCopyright.i18n'

interface StateProps {
  i18n: I18n
  hrefPrivacy: string
  hrefTerms: string
}

export class FooterCopyrightPres extends React.Component<StateProps> {
  render (): ReactElement {
    const { i18n, hrefPrivacy, hrefTerms } = this.props
    return (
      <div
        style={{
          backgroundColor: '#001523',
          padding: '10px 0',
        }}
        className='layout-footer-copyright-box'
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: '14px',
          }}
          className="layout-main-box copyright-container"
        >
          <span style={{ marginLeft: 25, color: BLUE_LIGHT }}>{i18n.COPYRIGHT}</span>
          <div style={{ display: 'flex' }} className="copyright-container">
            <a href={hrefPrivacy} style={{ marginLeft: 25, color: BLUE_LIGHT }}>
              {i18n.PRIVACY}
            </a>
            <a href={hrefTerms} style={{ marginLeft: 25, color: BLUE_LIGHT }}>
              {i18n.TERMS}
            </a>
          </div>
        </div>
      </div>
    )
  }
}

interface Props {}

const mapStateToProps: MapStateToProps<StateProps, Props, State> = (
  state,
  props,
): StateProps => {
  const { URL_PRIVACY_POLICY, URL_SHOPIFY } = Config.get().value ?? {}
  return {
    i18n: getI18n(state.language),
    hrefPrivacy: URL_PRIVACY_POLICY ?? '/',
    hrefTerms: URL_SHOPIFY ? `${URL_SHOPIFY}/policies/terms-of-service` : '/',
  }
}

export const FooterCopyright = connect(mapStateToProps)(FooterCopyrightPres)
