import { Config } from '../../redux/State/Config'

export type NavItem =
  | {
    title: string
    items: Array<{
      title: string
      url: string
    }>
    url?: undefined
  }
  | {
    title: string
    url: string
    items?: undefined
  }

export async function getNavItems (): Promise<NavItem[]> {
  const config = await Config.promise
  return [
    {
      title: 'Patients',
      items: [
        {
          title: 'Compassionate Pricing',
          url: `${config.URL_SHOPIFY}/blogs/news/compassionate-pricing`,
        },
        {
          title: 'Veterans Coverage',
          url: `${config.URL_SHOPIFY}/blogs/news/veterans-coverage`,
        },
        {
          title: 'Register',
          url: `${config.URL_SHOPIFY}/pages/patients`,
        },
      ],
    },
    {
      title: 'Why Aurora',
      items: [
        {
          title: 'Our Brands',
          url: `${config.URL_SHOPIFY}/pages/our-brands`,
        },
      ],
    },
    {
      title: 'Learn',
      items: [
        {
          title: 'Blog',
          url: `${config.URL_SHOPIFY}/blogs/news`,
        },
        {
          title: 'FAQ',
          url: `${config.URL_SHOPIFY}/pages/faqs`,
        },
      ],
    },
    {
      title: 'Shop',
      url: config.URL_SHOP,
    },
  ]
}

export async function getContactLink (): Promise<string> {
  const config = await Config.promise
  return `${config.URL_SHOPIFY}/pages/contact`
}
