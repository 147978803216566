export interface I18n {
  INSTRUCTIONS: string
  APPLY: string
  RENEW: string
  NAME_OR_ADDRESS: string
  INTRO: string
  HEADING: string
}

export const getI18n = (language: string): I18n => {
  const frCA = language === 'fr-CA'
  return {
    INSTRUCTIONS: frCA
      ? 'Une demande de renouvellement est requise pour chaque nouveau document médical soumis. Ceci peut être complété en cliquant sur le lien ci-dessus pour postuler ou renouveler votre inscription. Les modifications nécessitant la signature d\'une partie secondaire, comme un soignant ou un directeur d\'établissement, ne peut être soumis qu\'en remplissant la demande d\'inscription ci-dessous. Veuillez remplir seulement l’une des 2 options pour postuler ou renouveler votre inscription.'
      : 'A Renewal Application is required with each new medical document submitted. This can be completed by clicking on the link above to apply or renew your registration. Changes that require a signature from a secondary party, such as a caregiver or an institution manager, can only be submitted by filling out the Registration Application below. Please only complete 1 of the 2 options to apply or renew your registration.',
    APPLY: frCA ? 'Demande d’inscription' : 'Apply for Registration',
    RENEW: frCA ? 'Demande/Renouveler l’inscription' : 'Apply/Renew Registration',
    NAME_OR_ADDRESS: frCA
      ? 'Modifiez votre nom ou adresse'
      : 'Amend Name or Address',
    INTRO: frCA
      ? 'Les modifications apportées à votre inscription affecteront l’état de votre compte et nécessiteront du temps pour traiter votre demande. Nous vous aviserons par courriel une fois approuvé.'
      : 'Changes made to the client application will affect the users status and will require time to be approved. Please await an email to notify you of approval.',
    HEADING: frCA ? 'Inscription de Client ' : 'Client Application ',
  }
}
